<div class="page-wrapper">
    <div class="authentication-box">
        <div class="container">
            <div class="row">
                
                <div class="col-md-5 p-0 card-left">
                    <div class="card" style="background-color: white;">
                        <img src="..\..\..\assets\images\dashboard\main.png">
                    </div>
                </div>
                
                <div class="col-md-7 p-0 card-right">
                    <div class="card tab2-card">
                        <div class="card-body"  style="height: 375px;">
                            <ul ngbNav #nav="ngbNav" [(activeId)]="active" class="nav-tabs">
                                
                                <li [ngbNavItem]="1">
                                  <a ngbNavLink>Login</a>
                                  <ng-template ngbNavContent>
                                    <div class="tab-pane fade active show" id="account" role="tabpanel"
                                    aria-labelledby="account-tab">
                                    <form [formGroup]="loginForm" 
                                        class="form-horizontal auth-form" novalidate="">
                                        <div class="form-group">
                                            <input required="true" formControlName="email" type="email"
                                                class="form-control" placeholder="admin@printwave.id">
                                        </div>
                                        <div class="form-group">
                                            <input required="true" formControlName="password" type="password"
                                                class="form-control" placeholder="password">
                                        </div>
                                        <!-- <div class="form-terms">
                                            <div class="custom-control custom-checkbox d-flex align-items-center flex-wrap">
                                                <input type="checkbox" class="custom-control-input me-2"
                                                    id="customControlAutosizing">
                                                <label class="custom-control-label"
                                                    for="customControlAutosizing">Remember me</label>
                                                <a href="javascript:void(0)"
                                                    class="btn btn-default forgot-pass ms-auto">Forgot password</a>
                                            </div>
                                        </div> -->
                                        <div class="form-button">
                                            <a> <button class="btn btn-primary" type="submit" (click)="onSubmit()"> Login</button></a>
                                        </div>
                                    </form>
                                </div>
                                  </ng-template>
                                </li>
                                
                                <!-- <li [ngbNavItem]="2">
                                  <a ngbNavLink>Register</a>
                                  <ng-template ngbNavContent>
                                    <form [formGroup]="registerForm" 
                                    class="form-horizontal auth-form" novalidate>
                                    <div class="form-group">
                                        <input required="" name="login[username]" type="email"
                                            class="form-control" placeholder="Username"
                                            id="exampleInputEmail12">
                                    </div>
                                    <div class="form-group">
                                        <input required="" name="login[password]" type="password"
                                            class="form-control" placeholder="Password">
                                    </div>
                                    <div class="form-terms">
                                        <div class="custom-control custom-checkbox d-flex">
                                            <input type="checkbox" class="custom-control-input mt-1 me-2"
                                                id="customControlAutosizing1">
                                            <label class="custom-control-label"
                                                for="customControlAutosizing1"><span>I agree all statements
                                                    in <a>terms and conditions</a></span></label>
                                        </div>
                                    </div>
                                    <div class="form-button">
                                        <a [routerLink]="'/dashboard/default'">  <button class="btn btn-primary" type="submit">Register</button></a>
                                    </div>
                                </form>
                                  </ng-template>
                                </li> -->
                              </ul>
                              <div [ngbNavOutlet]="nav" class="mt-2"></div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>