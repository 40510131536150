import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { BehaviorSubject, Observable, Subject } from 'rxjs';

const API_Category = environment.base_url + 'category'
const API_Collection = environment.base_url + 'collection'
const API_Product = environment.base_url + 'products'
const product_images_url = String(environment.base_url + 'product-images')

const httpOptions = {
  headers: new HttpHeaders({ 'Content-Type': 'application/json' }),
};


@Injectable({
  providedIn: 'root'
})
export class ProductService {
  productCreateSubject = new BehaviorSubject<string>('Loading');
  productEditSubject = new BehaviorSubject<string>('Loading');

  productCreate$ = this.productCreateSubject.asObservable();
  productEdit$ = this.productEditSubject.asObservable();

  constructor(private http: HttpClient) { }

  categoryGet() {
    return this.http.get<any>(API_Category);
  }

  categoryCreate(data) {
    return this.http.post<any>(API_Category, data);
  }

  categoryDelete(id: number) {
    return this.http.delete<any>(API_Category + '/' + id);
  }

  collectionGet() {
    return this.http.get<any>(API_Collection);
  }

  collectionCreate(data) {
    return this.http.post<any>(API_Collection, data);
  }

  collectionDelete(id: number) {
    return this.http.delete<any>(API_Collection + '/' + id);
  }

  productGet() {
    return this.http.get<any>(API_Product);
  }

  productDelete(id: number) {
    return this.http.delete<any>(API_Product + '/' + id)
  }

  productDetailGet(id: number) {
    return this.http.get<any>(API_Product + '/' + id);
  }

  productCreate(form: any, images: any) {
    this.productCreateSubject.next('Product Sent');
    // console.log("Submitted Product Add form: ", form)
    let product_id = 0;
    const product_create_url = String(API_Product + '/' + 'create')
    const product_create_form = {
      title: form.title,
      description: form.description,
      status: form.status,
      stock: form.stock,
      sku: form.sku,
      weight: form.weight,
      dimension: form.dimensions,
      category: form.category,
      new: form.new,
      tags: String(form.tags),
    }
    const product_create_body = JSON.stringify(product_create_form);
    console.log("Sent Product Create: ", product_create_form)
    this.http.post<any>(product_create_url, product_create_body, httpOptions).subscribe((res) => {
      console.log("Product Create Response:", res)
      this.productCreateSubject.next('Product Created');
      product_id = res.id
      for (let price of form.price) {
        const product_price_url = String(environment.base_url + 'product-price')
        const product_price_form = {
          product: product_id,
          min: price.min,
          max: price.max,
          value: price.value
        }
        const product_price_body = JSON.stringify(product_price_form)
        console.log("Sent Product Price: ", product_price_form)
        this.http.post<any>(product_price_url, product_price_body, httpOptions).subscribe((res) => {
          console.log("Product Price Response:", res)
          this.productCreateSubject.next('Product Price Created');
        })
      }
      for (let col of form.collection) {
        const product_collection_url = String(environment.base_url + 'product-collection')
        const product_collection_form = {
          product: product_id,
          collection: col,
        }
        const product_collection_body = JSON.stringify(product_collection_form)
        console.log("Sent Product Collection: ", product_collection_form)
        this.http.post<any>(product_collection_url, product_collection_body, httpOptions).subscribe((res) => {
          console.log("Product Collection Response:", res)
          this.productCreateSubject.next('Product Collection Created');
        })
      }
      const product_option_url = String(environment.base_url + 'product-options')
      for (let opt of form.option) {
        const product_option_form = {
          product: product_id,
          name: opt.name,
          sub_options: opt.sub_options
        }
        const product_option_body = JSON.stringify(product_option_form)
        console.log("Sent Product Option: ", product_option_form)
        this.http.post<any>(product_option_url, product_option_body, httpOptions).subscribe((res) => {
          console.log("Product Option Response:", res)
          this.productCreateSubject.next('Product Options Created');
        })
      }
      // console.log(images)
      if (images[0].img != null) {this.uploadImage(images[0], product_id).subscribe((res) => {
          console.log("Product Image 0 Response:", res)
          if (images[1].img != null) {this.uploadImage(images[1], product_id).subscribe((res) => {
            console.log("Product Image 1 Response:", res)
            if (images[2].img != null) {this.uploadImage(images[2], product_id).subscribe((res) => {
              console.log("Product Image 2 Response:", res)
              if (images[3].img != null) {this.uploadImage(images[3], product_id).subscribe((res) => {
                console.log("Product Image 3 Response:", res)
                if (images[4].img != null) {this.uploadImage(images[4], product_id).subscribe((res) => {
                  console.log("Product Image 4 Response:", res)
                  if (images[5].img != null) {this.uploadImage(images[5], product_id).subscribe((res) => {
                    console.log("Product Image 65Response:", res)
                    this.productCreateSubject.next('Product Images Created');
                });}
              });}
            });}
          });}
        });}
      });}
      this.productCreateSubject.next('Product Create Completed');
    })
  }

  uploadImage(url, product_id): Observable<any> {
    const formData = new FormData();
    console.log(url.img)
    const file = ImageURLtoFile(url.img)
    // console.log(file)
    formData.append('product', String(product_id));
    formData.append('image', file);
    return this.http.post<any>(product_images_url, formData)
  }
  
  productEdit(form: any, id: number, images: any) {
    this.productEditSubject.next('Product Sent');
    // console.log("Submitted Product Add form: ", form)
    let product_id = 0;
    const product_create_url = String(API_Product + '/' + id)
    const product_create_form = {
      title: form.title,
      description: form.description,
      status: form.status,
      stock: form.stock,
      sku: form.sku,
      weight: form.weight,
      dimension: form.dimensions,
      category: form.category,
      new: form.new,
      tags: String(form.tags),
    }
    const product_create_body = JSON.stringify(product_create_form);
    console.log("Sent Product Create: ", product_create_form)
    this.http.put<any>(product_create_url, product_create_body, httpOptions).subscribe((res) => {
      console.log("Product Edit Response:", res)
      this.productEditSubject.next('Product Edited');
      product_id = res.id
      for (let price of form.price) {
        const product_price_url = String(environment.base_url + 'product-price' + '/' + price.id)
        const product_price_form = {
          product: product_id,
          min: price.min,
          max: price.max,
          value: price.value
        }
        const product_price_body = JSON.stringify(product_price_form)
        console.log("Sent Product Price: ", product_price_form)
        this.http.patch<any>(product_price_url, product_price_body, httpOptions).subscribe((res) => {
          console.log("Product Price Response:", res)
          this.productEditSubject.next('Product Price Edited');
        })
      }
      // for (let col of form.collection) {
      //   const product_collection_url = String(environment.base_url + 'product-collection')
      //   const product_collection_form = {
      //     product: product_id,
      //     collection: col,
      //   }
      //   const product_collection_body = JSON.stringify(product_collection_form)
      //   console.log("Sent Product Collection: ", product_collection_form)
      //   this.http.post<any>(product_collection_url, product_collection_body, httpOptions).subscribe((res) => {
      //     console.log("Product Collection Response:", res)
      //     this.productEditSubject.next('Product Collection Edited');
      //   })
      // }
      for (let opt of form.option) {
        const product_option_url = String(environment.base_url + 'product-options' + '/' + opt.id)
        const product_option_form = {
          product: product_id,
          name: opt.name,
          sub_options: opt.sub_options
        }
        const product_option_body = JSON.stringify(product_option_form)
        console.log("Sent Product Option: ", product_option_form)
        this.http.patch<any>(product_option_url, product_option_body, httpOptions).subscribe((res) => {
          console.log("Product Option Response:", res)
          this.productEditSubject.next('Product Options Edited');
        })
      }
      // const product_images_url = String(environment.base_url + 'product-images')
      // console.log(images)
      // for (let url of images) {
      //   if (url.img != null) {
      //     const formData = new FormData();
      //     console.log(url.img)
      //     const file = ImageURLtoFile(url.img)
      //     // console.log(file)
      //     formData.append('product', String(product_id));
      //     formData.append('image', file);
      //     this.http.post<any>(product_images_url, formData).subscribe((res) => {
      //       console.log("Product Image Response:", res)
      //       this.productEditSubject.next('Product Images Edited');
      //     });
      //   }
      // }
      this.productEditSubject.next('Product Edit Completed');
    })
  }
}

function ImageURLtoFile(data: string) {
  const splitDataURI = data.split(',')
  const byteString = splitDataURI[0].indexOf('base64') >= 0 ? atob(splitDataURI[1]) : decodeURI(splitDataURI[1])
  const mimeString = splitDataURI[0].split(':')[1].split(';')[0]

  const ia = new Uint8Array(byteString.length)
  for (let i = 0; i < byteString.length; i++)
      ia[i] = byteString.charCodeAt(i)
  const blob = new Blob([ia], { type: mimeString })
  const file = new File([blob], "image.jpg", { type: mimeString });
  return file
}
