<!-- Container-fluid starts-->
<div class="container-fluid">
  <div class="row">
    <div class="col-sm-12">
      <div class="card">
        <div class="card-header">
          <h5>Orders</h5>
        </div>
        <div class="card-body">
          <div class="custom-datatable">
            <form>
              <div class="mb-3">
                <div style="display: block; display: flex;">
                  <!-- <button style="margin-right: 10px" class="button" class="btn btn-primary" data-toggle="modal" data-target="#exampleModal" (click)="refresh()"><span class="fa fa-refresh"></span></button> -->
                  <input type='text' class="filter-ngx form-control" placeholder='Search...' [(ngModel)]="searchText" autocomplete="off"/>
                </div>
              </div>
            </form>
            <div *ngIf="tableState == 'loading'" style="margin-left: 5px;">Loading...</div>
            <table class="table table-striped" *ngIf="tableState == 'loaded'">
              <thead>
                <tr>
                  <th scope="col" sortable="items" (sort)="onSort($event)">Items</th>
                  <th scope="col" sortable="progress" (sort)="onSort($event)">Progress</th>
                  <th scope="col" sortable="view" (sort)="onSort($event)">View</th>
                  <th scope="col" sortable="edit" (sort)="onSort($event)">Edit</th>
                  <th scope="col" sortable="total" (sort)="onSort($event)">Total</th>
                </tr>
              </thead>
              <tbody>
                <tr *ngFor="let item of (tableItem$ | async)">
                  <td>
                    <span *ngFor="let order of item.order_items">
                      <p>{{order.product.title}}</p>
                    </span>
                  </td>
                  <td>
                    <span class='badge badge-{{item.progress}}'>{{ item.progress }}</span>
                  </td>
                  <td>
                    <button type="button" class="btn btn-secondary" data-toggle="modal" data-target="#exampleModal" (click)="openOrderDetails(orderDetailsPanel, item)"><span class="fa fa-eye"></span></button>
                  </td>
                  <td>
                    <button type="button" class="btn btn-primary" data-toggle="modal" data-target="#exampleModal" (click)="openUpdateProgress(updateProgress, item)"><span class="fa fa-pencil"></span></button>
                  </td>
                  <td>{{item.total_price}}</td>
                </tr>
              </tbody>
            </table>

            <ng-template #updateProgress let-modal let-data="data">
              <div class="modal-header">
                  <h5 class="modal-title f-w-600" id="exampleModalLabel">Update Order Progress</h5>
              </div>
              <div class="modal-body">
                  <form class="needs-validation" [formGroup]="progressForm">
                      <div class="form">
                          <div class="form-group">
                              <label for="validationCustom01" class="mb-1">Progress Status:</label>
                              <select class="form-control" formControlName="progress">
                                <option value='RECEIVED'>Received</option>
                                <option value='PROCESSED'>Processed </option>
                                <option value='ON_DELIVERY'>On Delivery</option>
                                <option value="COMPLETED">Completed</option>
                              </select>
                              <div *ngIf="submitted && f['progress'].errors && f['progress'].errors['required']" class="form-error">Please fill out this field</div>
                          </div>
                      </div>
                  </form>
              </div>
              <div class="modal-footer">
                  <button type="button" class="btn btn-primary" (click)="saveProgress()">Save</button>
                  <button class="btn btn-secondary" type="button" data-dismiss="modal"
                      (click)="modal.dismiss('Cross click')">Close</button>
              </div>
            </ng-template>

            <ng-template #orderDetailsPanel let-modal>
              <div class="modal-header">
                  <h5 class="modal-title f-w-600" id="exampleModalLabel">View Order</h5>
                </div>
                <div class="modal-body" *ngIf="orderDetailsState == 'loading'">
                  <p>Loading...</p>
                </div>
                <div class="modal-body" *ngIf="orderDetailsState == 'loaded'">
                  <div class="details-header">
                    <p>{{ orderDetails.order_id }}</p>
                    <p><strong>Shipment:</strong> {{ orderDetails.shipment }}</p>
                    <p><strong>Price:</strong> Rp. {{ orderDetails.total_price }}</p>
                  </div>
                  <div class="details-item" *ngFor="let item of orderDetails.order_items">
                    <p><strong>Item:</strong> {{ item.product.title }}</p>
                    <p><strong>Quantity:</strong> {{ item.quantity }}</p>
                    <p><strong>Price:</strong> Rp.{{ item.total_price }}</p>
                    <p><strong>Options:</strong></p>
                    <div class="details-options">
                      <p *ngFor="let opt of item.options.option" style="margin-left: 20px;">{{opt.value.name}}</p>
                    </div>
                    <a class="btn btn-primary" type="button" [href]="item.file_url" target="_blank" style="margin-top: 10px;"><span class="fa fa-download"></span> Design File</a>
                  </div>
                  <div class="details-note">
                    <p><strong>Notes:</strong> {{ orderDetails.order_notes }}</p>
                  </div>
                </div>
              <div class="modal-footer">
                  <button class="btn btn-secondary" type="button" data-dismiss="modal"
                      (click)="modal.dismiss('Cross click')">Close</button>
              </div>
            </ng-template>

            <div class="d-flex justify-content-between p-2">
              <ngb-pagination [collectionSize]="(total$ | async)!" [(page)]="service.page"
                [pageSize]="service.pageSize">
              </ngb-pagination>
              <select class="form-select" style="width: auto" name="pageSize" [(ngModel)]="service.pageSize">
                <option [value]="10" [selected] ="true" [ngValue]="2">2 items per page</option>
                <option [ngValue]="4">4 items per page</option>
                <option [ngValue]="6">6 items per page</option>
              </select>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <!-- Container-fluid Ends-->
