import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { BehaviorSubject, Observable, Subject } from 'rxjs';

const API_Admin_Order = environment.base_url + 'admin/orders'

const httpOptions = {
  headers: new HttpHeaders({ 'Content-Type': 'application/json' }),
};


@Injectable({
  providedIn: 'root'
})
export class OrderService {
  orderEditSubject = new BehaviorSubject<string>('Loading');

  orderEdit$ = this.orderEditSubject.asObservable();

  constructor(private http: HttpClient) { }

  orderGet() {
    return this.http.get<any>(API_Admin_Order);
  }

  detailsGet(order_id) {
    const url = API_Admin_Order + '/' + order_id
    return this.http.get<any>(url);
  }

  progressUpdate(data) {
    const url = API_Admin_Order + '/' + data.order_id
    console.log("Progress Update: ", data.progress)
    return this.http.patch<any>(url, data);
  }

}
