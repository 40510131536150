import { Component, OnInit, QueryList, ViewChildren } from '@angular/core';
import { NgbModal, ModalDismissReasons } from '@ng-bootstrap/ng-bootstrap';
import { SortEvent } from 'src/app/shared/directives/shorting.directive';
import { NgbdSortableHeader } from "src/app/shared/directives/NgbdSortableHeader";
import { TableService } from 'src/app/shared/service/table.service';
import { Observable } from 'rxjs';
import { DecimalPipe } from '@angular/common';
import { AbstractControl, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { OrderService } from 'src/app/shared/service/order.service';
import { Router } from '@angular/router';
import { HttpClient } from '@angular/common/http';

@Component({
  selector: 'app-orders',
  templateUrl: './orders.component.html',
  styleUrls: ['./orders.component.scss'],
  providers: [TableService, DecimalPipe],
})

export class OrdersComponent implements OnInit {
  public closeResult: string;
  public tableItem$: Observable<any[]>;
  public searchText;
  total$: Observable<number>;

  progressForm: FormGroup;
  submitted = false;
  orderData: any[]
  orderDetails: any = null
  tableState = 'loading'
  orderDetailsState = 'loading'
  
  constructor(
    public service: TableService, 
    public router: Router,
    private modalService: NgbModal,
    private orderService: OrderService,
    private fb: FormBuilder,
    private http: HttpClient,
  ) {
    this.orderService.orderGet().subscribe((res) => {
      this.orderData = res.sort((a, b) => b.id - a.id)
      this.tableItem$ = this.service.tableItem$;
      this.total$ = this.service.total$;
      // console.log('Orders: ', this.orderData)
      this.service.setUserData(this.orderData)
      this.service.reloadTable()
      this.tableState = 'loaded'
    });
  }

  @ViewChildren(NgbdSortableHeader) headers: QueryList<NgbdSortableHeader>;

  onSort({ column, direction }: SortEvent) {
    // resetting other headers
    this.headers.forEach((header) => {
      if (header.sortable !== column) {
        header.direction = '';
      }
    });
    this.service.sortColumn = column;
    this.service.sortDirection = direction;
  }

  openOrderDetails(content, item) {
    this.orderService.detailsGet(item.order_id).subscribe({
      next: (res) => {
        console.log("Response: ", res)
        this.orderDetails = res
        this.orderDetails.order_items.forEach(item => {
          if (item.options) {
            try {
              item.options = JSON.parse(item.options);
            } catch (error) {
              console.error('Error parsing options for item', item, error);
              item.options = null;
            }
          }
        });
        this.orderDetailsState = 'loaded'
      },
      error: (error: any) => {
        console.log("Error: ", error.error.detail)
        return;
      },
    });
    this.modalService.open(content, { ariaLabelledBy: 'modal-basic-title' }).result.then((result) => {
      this.closeResult = `Closed with: ${result}`;
    }, (reason) => {
      this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
    });
  }

  openUpdateProgress(content, item) {
    // console.log(item)
    this.progressForm.controls['order_id'].setValue(item.order_id)
    this.progressForm.controls['progress'].setValue(item.progress)
    this.modalService.open(content, { ariaLabelledBy: 'modal-basic-title'}).result.then((result) => {
      this.closeResult = `Closed with: ${result}`;
    }, (reason) => {
      this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
    });
  }

  saveProgress() {
    this.orderService.progressUpdate(this.progressForm.value).subscribe({
      next: (res) => {
        console.log("Response: ", res)
        this.modalService.dismissAll()
        this.router.navigateByUrl('/', { skipLocationChange: true }).then(() => {
          this.router.navigate(['/sales/orders']);
        });
      },
      error: (error: any) => {
        console.log("Error: ", error.error.detail)
        return;
      },
    });
  }

  private getDismissReason(reason: any): string {
    if (reason === ModalDismissReasons.ESC) {
      return 'by pressing ESC';
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      return 'by clicking on a backdrop';
    } else {
      return `with: ${reason}`;
    }
  }

  ngOnInit() {
    this.progressForm = this.fb.group(
      {
        order_id: ['', Validators.required],
        progress: ['', Validators.required],
      }
    );
  }

  get f(): { [key: string]: AbstractControl } {
    return this.progressForm.controls;
  }
}
