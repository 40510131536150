<div class="container-fluid">
    <div class="card">
        <div class="row product-page-main card-body">
            <div class="col-xl-3">
                <section>
                     <ks-carousel class="img-car" [id]="0" [images]="images">
                        <!-- | slice:1 -->
                    </ks-carousel>
                </section>
            </div>
            <div class="col-xl-9">
                <div class="product-page-details product-right mb-0">
                    <h2>{{product.title}}</h2>
                    <hr>
                    <h5 class="product-title">product details</h5>
                    <div [innerHtml]="product.description"></div>
                    <hr>
                    <div class="product-price digits mt-2">
                        <div class="option-table">
                            <label>Product Base Price</label>
                            <table>
                                <thead>
                                    <tr>
                                        <td scope="col">Quantity</td>
                                        <td scope="col">Price</td>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr *ngFor="let price of product.price">
                                        <td>{{price.min}}-{{price.max}}</td>
                                        <td>Rp.{{price.value}}</td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                    <hr>
                    <!-- Additional Options Here -->
                    <div id="submit" [formGroup]="optionForm" novalidate>
                        <div formArrayName="option">
                            <div *ngFor="let option of product.options; let i=index">
                                <div [formGroupName]="i">
                                    <div class="form-group" (change)="calcFinalPrice()">
                                        <h5>{{option.name}}</h5>
                                        <select class="form-control" formControlName="value">
                                            <option *ngFor="let sub_options of product.options[i].sub_options" [ngValue]="sub_options">{{ sub_options.name }}</option>
                                        </select>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div *ngFor="let table of optionForm.value.option">
                        <div *ngIf="table.value != ''" class="option-table product-form-box">
                        <label>{{table.value.name}}</label>
                        <table>
                            <thead>
                                    <tr>
                                        <td scope="col">Quantity</td>
                                        <td scope="col">Price</td>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr *ngFor="let price of table.value.prices">
                                        <td>{{price.min}}-{{price.max}}</td>
                                        <td>+Rp.{{price.value}}</td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                    <hr>
                    <div class="add-product-form">
                        <h6 class="product-title">quantity</h6>
                        <div class="qty-box1">
                            <div class="input-group">
                                <i class="fa fa-minus btnGtr1" (click)=decrement()></i>
                                <input class="touchspin1 text-center" name="counter" [(ngModel)]="counter" type="number" (change)="calcFinalPrice()">
                                <i class="fa fa-plus btnLess1" (click)=increment()></i>
                            </div>
                        </div>
                    </div>
                    <div class="product-price digits mt-2">
                        <h3>Rp.{{finalPrice}}</h3>
                    </div>
                    <hr>
                    <div class="m-t-15">
                        <!-- <button class="btn btn-primary m-r-10" type="button">Add To Cart</button> -->
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>