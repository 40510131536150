import { Component, OnInit } from '@angular/core';
import { AbstractControl, FormArray, FormControl, FormGroup, UntypedFormBuilder, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { ChangeEvent } from '@ckeditor/ckeditor5-angular';
import * as ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import { ProductService } from 'src/app/shared/service/product.service';

@Component({
  selector: 'app-product-edit',
  templateUrl: './product-edit.component.html',
  styleUrls: ['./product-edit.component.scss']
})
export class ProductEditComponent implements OnInit {
  public Editor = ClassicEditor;
  private editorContent = '';
  private editorInstance: any;

  public counter: number = 1;
  public images = [
    {img: null,},
    {img: null,},
    {img: null,},
    {img: null,},
    {img: null,},
    {img: null,}
  ]
  thumbnail = null;

  categories = []
  collections = []
  dimensionL = 0
  dimensionW = 0
  dimensionT = 0

  productId: number
  product: null
  showForm: boolean = false
  id: number = 0

  constructor(
    private fb: UntypedFormBuilder,
    private activatedRoute: ActivatedRoute,
    private productService: ProductService,
    private router: Router
  ) {
    this.productId = Number(this.activatedRoute.snapshot.paramMap.get('productId'))
    this.productService.productDetailGet(this.productId).subscribe((res) => {
      console.log(res)
      this.product = res
      this.productForm = this.fb.group({
        title: [res.title, [Validators.required]],
        description: [res.description, [Validators.required]],
        status: [res.status, [Validators.required]],
        price: this.fb.array([]),
        stock: [res.stock, [Validators.required, Validators.min(1)]],
        sku: [res.sku, [Validators.required]],
        weight: [res.weight, [Validators.required]],
        dimensions: [res.dimension, [Validators.required]],
        category: [res.category_details.id, [Validators.required]],
        collection: ['', [Validators.required]],
        new: [res.new, [Validators.required]],
        tags: [[]],
        images: [res.images],
        option: this.fb.array([]),
      })
      res.price.forEach(element => {
        let price = this.fb.group({
          id: [element.id],
          min: [element.min, [Validators.required, Validators.min(1)]],
          max: [element.max, [Validators.required, Validators.min(2), Validators.max(9999)]],
          value: [element.value, [Validators.required]],
        })
        this.prices.push(price);
      });
      res.options.forEach((item, index) => {
        let option = this.fb.group({
          product: [item.product],
          name: [item.name, Validators.required],
          sub_options: this.fb.array([]),
        });
        this.options.push(option);
        item.sub_options.forEach((subItem, subIndex) => {
          let suboption = this.fb.group({
            name: [subItem.name, Validators.required],
            prices: this.fb.array([])
          });
          this.getSuboptions(index).push(suboption);
          subItem.prices.forEach((subSubItem) => {
            const prices = this.fb.group({
              min: [subSubItem.min, [Validators.required, Validators.min(1)]],
              max: [subSubItem.max, [Validators.required, Validators.min(2), Validators.max(9999)]],
              value: [subSubItem.value, [Validators.required]]
            });
            this.getPrice(index, subIndex).push(prices);
          })
        })
      });
      this.productService.collectionGet().subscribe((col) => {
        // console.log(res.collections)
        // console.log("Collection List: ", col)
        this.collections = col
        const ids = res.collections.map(cn => {
          const found = col.find(ref => ref.name === cn.name);
          return found ? found.id : null;  // Return the id if found, otherwise null
        });
        ids.forEach((item) => {
          if (item != null) {
            this.collectionsHolder.push(item);
            this.productForm.controls['collection'].setValue("");
          }
        })
      })
      if (res.tags != "") {
        res.tags.split(',').forEach(item => {
          this.tagsHolder.push(item);
          this.productForm.controls['tags'].setValue("");
        });
      };
      this.dimensionL = res.dimension?.split('x')[0]
      this.dimensionW = res.dimension?.split('x')[1]
      this.dimensionT = res.dimension?.split('x')[2]
      this.editorContent = res.description
      res.images.forEach((item, index) => {
        this.images[index].img = item.image_url
      })
      this.id = res.id
      this.showForm = true;
    });
  }
  
  productForm: FormGroup
  submitted = false
  collectionsHolder: string[] = [];
  tagsHolder: string[] = [];
  
  ngOnInit() {
    this.productService.categoryGet().subscribe((res) => {
      // console.log("Category List: ", res)
      this.categories = res
    })
  }

  onSubmit() {
    this.submitted = true;
    this.productForm.controls['dimensions'].setValue(String(this.dimensionL + "x" + this.dimensionW + "x" + this.dimensionT));
    this.productForm.controls['collection'].setValue(this.collectionsHolder);
    this.productForm.controls['tags'].setValue(this.tagsHolder);
    // console.log(this.images)
    console.log(this.productForm.value)
    if (this.productForm.invalid) {
      console.log("Edit form invalid")
      return;
    }
    this.productService.productEdit(this.productForm.value, this.id, this.images)
    this.productService.productEditSubject.subscribe((res) => {
      console.log("Product Edit Progres: ", res)
      if (res == 'Product Edit Completed') {
        this.router.navigateByUrl('/products', { skipLocationChange: true }).then(() => {
          this.router.navigate([String('/products/product-list')]);
        });
      }
    })
  }

  onReady(editor) {
    this.editorInstance = editor;
    if (this.editorInstance) {
      this.editorInstance.setData(this.editorContent);
    }
  }

  get options(): FormArray {
    return (this.productForm.get('option') as FormArray);
  }
  get prices(): FormArray {
    return (this.productForm.get('price') as FormArray);
  }

  getSuboptions(suboptionIndex: number) {
    return (this.options.at(suboptionIndex) as FormGroup).get('sub_options') as FormArray;
  }

  getPrice(suboptionIndex: number, priceIndex: number) {
    return (this.getSuboptions(suboptionIndex).at(priceIndex) as FormGroup).get('prices') as FormArray;
  }
  
  addOption() {
    let option = this.fb.group({
      name: ['', Validators.required],
      sub_options: this.fb.array([
        this.fb.group({
          product: [0],
          name: ['', Validators.required],
          prices: this.fb.array([
            this.fb.group({
              min: ['', [Validators.required, Validators.min(1)]],
              max: ['', [Validators.required, Validators.min(2), Validators.max(9999)]],
              value: ['', [Validators.required]],
            })
          ])
        })
      ]),
    });
    this.options.push(option);
  }

  addBasePrice() {
    let price = this.fb.group({
      id: [0],
      min: ['', [Validators.required, Validators.min(1)]],
      max: ['', [Validators.required, Validators.min(2), Validators.max(9999)]],
      value: ['', [Validators.required]],
    })
    this.prices.push(price);
  }
  
  deleteOption(index: number) {
    this.options.removeAt(index);
  }

  deleteBasePrice(index: number) {
    this.prices.removeAt(index);
  }
  
  addSuboption(suboptionIndex: number) {
    const suboption = this.fb.group({
      name: ['', Validators.required],
      prices: this.fb.array([
        this.fb.group({
          min: ['', [Validators.required, Validators.min(1)]],
          max: ['', [Validators.required, Validators.min(2), Validators.max(9999)]],
          value: ['', [Validators.required]],
        })
      ])
    });
    this.getSuboptions(suboptionIndex).push(suboption);
  }

  deleteSuboption(suboptionIndex: number, index) {
    this.getSuboptions(suboptionIndex).removeAt(index);
  }

  addPrice(suboptionIndex: number, priceIndex: number) {
    const prices = this.fb.group({
      min: ['', [Validators.required, Validators.min(1)]],
      max: ['', [Validators.required, Validators.min(2), Validators.max(9999)]],
      value: ['', [Validators.required]]
    });
    this.getPrice(suboptionIndex, priceIndex).push(prices);
  }

  deletePrice(suboptionIndex: number, priceIndex: number, prc: number) {
    this.getPrice(suboptionIndex, priceIndex).removeAt(prc)
  }

  onCollectionsSetKeydown() {
    if (this.productForm.value.collection == "" || this.productForm.value.collection == null) return;  
    this.collectionsHolder.push(this.productForm.value.collection);
    this.productForm.controls['collection'].setValue("");
  }
  
  dropCollection(index: any) {
    this.collectionsHolder.splice(index, 1);  
  }

  onTagsSetKeydown() {  
    if (this.productForm.value.tags == "" || this.productForm.value.tags == null) return;  
    this.tagsHolder.push(this.productForm.value.tags);
    this.productForm.controls['tags'].setValue("");
  }
  
  dropTag(index: any) {
    this.tagsHolder.splice(index, 1);  
  }

  //FileUpload
  readImages(event: any, i) {
    // console.log("Read image: ", i)
    if (event.target.files.length === 0)
      return;
    var mimeType = event.target.files[0].type;
    if (mimeType.match(/image\/*/) == null) {
      return;
    }
    // Image upload
    var reader = new FileReader();
    reader.readAsDataURL(event.target.files[0]);
    reader.onload = (_event) => {
      this.images[i].img = reader.result.toString();
      if (this.images[0].img != null) {
        this.thumbnail = ImageURLtoFile(this.images[0].img);
        // console.log(this.thumbnail)
      }
    }
    // console.log(this.url)
    // console.log(this.images)
  }

  deleteImage(i) {
    this.images[i].img = null
    if (i == 0) {
      this.thumbnail = null
    }
    // console.log(this.images)
  }

  get f(): { [key: string]: AbstractControl } {
    return this.productForm.controls;
  }

  public onEditorChange( { editor }: ChangeEvent ) {
    const data = editor.getData();
    this.productForm.controls['description'].setValue(data);
  }
  
  onReset() {
    this.router.navigate(['/products/product-list']);
  }
}

function ImageURLtoFile(data: string) {
  const splitDataURI = data.split(',')
  const byteString = splitDataURI[0].indexOf('base64') >= 0 ? atob(splitDataURI[1]) : decodeURI(splitDataURI[1])
  const mimeString = splitDataURI[0].split(':')[1].split(';')[0]

  const ia = new Uint8Array(byteString.length)
  for (let i = 0; i < byteString.length; i++)
      ia[i] = byteString.charCodeAt(i)
  const blob = new Blob([ia], { type: mimeString })
  const file = new File([blob], "image.jpg", { type: mimeString });
  return file
}