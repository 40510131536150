import { Component, OnInit } from '@angular/core';
import { AuthService } from 'src/app/shared/service/auth.service';

@Component({
  selector: 'app-profile',
  templateUrl: './profile.component.html',
  styleUrls: ['./profile.component.scss']
})
export class ProfileComponent implements OnInit {
  public active = 1;
  public isLoggedIn = false;
  public user = null;

  constructor(private authService: AuthService) { }

  ngOnInit() { 
    this.authService.isAuthenticatedUser().subscribe((res) => {
      this.isLoggedIn = res
      if (res) {
        this.authService.getCurrentUser().subscribe((user) => {
          // console.log("User Data: ", user)
          this.user = user
        })
      }
    })
  }
}
