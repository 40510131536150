import { Component, OnInit } from '@angular/core';
import { ProductService } from 'src/app/shared/service/product.service';
import { NgbModal, ModalDismissReasons } from '@ng-bootstrap/ng-bootstrap';
import { Router } from '@angular/router';

@Component({
  selector: 'app-product-list',
  templateUrl: './product-list.component.html',
  styleUrls: ['./product-list.component.scss']
})
export class ProductListComponent implements OnInit {
  public closeResult: string;

  public product_list = []

  constructor(
    private productService: ProductService, 
    private modalService: NgbModal,
    private router: Router
  ) { }

  ngOnInit() {
    this.productService.productGet().subscribe((res) => {
      this.product_list = res
      // console.log(res)
    });
  }

  openEdit(id: number) {
    // console.log("Opened Product: ", id)
    const rt = '/products/product-edit/' + id
    this.router.navigateByUrl(rt)
  }

  openDetail(id: number) {
    // console.log("Opened Product: ", id)
    const rt = '/products/product-detail/' + id
    this.router.navigateByUrl(rt)
  }
  
  openDelete(content, id: number) {
    // console.log("Opened Product Delete: ", id)
    this.modalService.open(content, { ariaLabelledBy: 'modal-basic-title' }).result.then((result) => {
      this.closeResult = `Closed with: ${result}`;
    }, (reason) => {
      this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
    });
  }
  private getDismissReason(reason: any): string {
    if (reason === ModalDismissReasons.ESC) {
      return 'by pressing ESC';
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      return 'by clicking on a backdrop';
    } else {
      return `with: ${reason}`;
    }
  }

  onDelete(id: number) {
    console.log("Deleted product: ", id)
    this.productService.productDelete(id).subscribe((res) => {
      console.log("Deleted Product with ID: ", id)
      this.router.navigateByUrl('/products', { skipLocationChange: true }).then(() => {
        this.router.navigate(['/products/product-list']);
      });
    })
  }
}
