<!-- Container-fluid starts-->
<div class="container-fluid">
    <div class="row">
      <div class="col-sm-12">
        <div class="card">
          <div class="card-header">
            <h5>Edit Product</h5>
          </div>
          <div class="card-body">
            <div class="row product-adding">
              <!-- <div class="col-xl-3">
              </div> -->
  
              <div class="col-xl-12">
                <div class="add-product">
                  <div class="col-xl-12 xl-100 col-sm-12 col-12" style="margin-right: 20px;">
                    <ul class="file-upload-product">
                      <li *ngFor="let item of images; let i=index">
                        <div class="box-input-file">
                          <!-- <input class="upload" type="file" (change)="readImages($event,i)"> -->
                          <img class="imgUpload" alt="" [src]="item.img" (error)="$event.target.src = '../../../../assets/images/dashboard/product/1.jpg'">
                          <!-- <button type="button" (click)="deleteImage(i)" class="btn-del-price fa fa-trash-o" style="color:red"></button> -->
                        </div>  
                      </li>
                    </ul>
                  </div>
                </div>
                <!-- <p>*First picture will be thumbnail. Use a PNG-8 file with low file size.</p> -->
  
                <hr>
  
                <form class="needs-validation" [formGroup]="productForm" *ngIf="showForm">
                  <!-- TITLE -->
                  <div class="add-product-form" style="margin-left: 70px;">
                    <div class="form-group row">
                      <div class="col-xl-3 col-sm-4">
                        <label for="title">Product Title</label>
                      </div>
                      <div class="col-xl-8 col-sm-7">
                        <input type="text" class="form-control" formControlName="title" placeholder="Print Kertas A0">
                        <div *ngIf="submitted && f['title'].errors && f['title'].errors['required']" class="form-error">Please fill out this field</div>
                      </div>
                    </div>
                    <!-- BASE PRICE -->
                    <div class="form-group row">
                      <div class="col-xl-3 col-sm-4">
                        <label>Base Price</label>
                      </div>
                      <div class="col-xl-8 col-sm-7">
                        <button type="button" (click)="addBasePrice()" class="btn-add">Add Price Adjustment</button>
                      </div>
                    </div>
                    <div class="form-group row" style="margin-right: 12px;">
                      <div class="col-xl-11 col-sm-11 border options-box" formArrayName="price">
                        <div *ngFor="let price of prices.controls; let prc = index" class="border box-option">
                          <div [formGroupName]="prc" class="box-adjustment">
                            <input formControlName="min" placeholder="Min" type="number" class="form-control form-price">
                            <input formControlName="max" placeholder="Max" type="number" class="form-control form-price">
                            <input formControlName="value" placeholder="Value" type="number" class="form-control form-price">
                            <button type="button" (click)="deleteBasePrice(prc)" class="btn-del-price fa fa-trash-o" style="color:red"></button>
                          </div>
                        </div>
                      </div>
                    </div>
                    <!-- DESCRIPTION -->
                    <div class="form-group row">
                      <div class="col-xl-3 col-sm-4"><label>Description :</label></div>
                      <div class="col-xl-8 col-sm-7">
                        <div class="ps-0 description-sm">
                          <ckeditor [editor]="Editor" (ready)="onReady($event)" (change)="onEditorChange($event)"></ckeditor>
                        </div>
                        <div *ngIf="submitted && f['description'].errors && f['description'].errors['required']" class="form-error">Please fill out this field</div>
                      </div>
                    </div>
                    <div class="form-group row">
                      <div class="col-xl-3 col-sm-4">
                        <label for="category">Category</label>
                      </div>
                      <div class="col-xl-8 col-sm-7">
                        <select class="form-control digits" formControlName="category">
                          <option *ngFor="let cat of categories" [value]="cat.id">{{cat.name}}</option>
                        </select>
                        <div *ngIf="submitted && f['category'].errors && f['category'].errors['required']" class="form-error">Please fill out this field</div>
                      </div>
                    </div>
                    <!-- COLLECTION -->
                    <div class="form-group row disabled-container">
                      <div class="col-xl-3 col-sm-4">
                        <label for="collection">Collection</label>
                      </div>
                      <div class="col-xl-8 col-sm-7">
                        <select class="form-control digits" formControlName="collection" (change)="onCollectionsSetKeydown()">
                          <option [ngValue]="''" [disabled]="true" >Select</option>
                          <option *ngFor="let col of collections" [value]="col.id">{{col.name}}</option>
                        </select>
                        <div *ngIf="submitted && f['collection'].errors && f['collection'].errors['required'] && collectionsHolder.length==0" class="form-error">Please fill out this field</div>
                      </div>
                      <div class="row mb-2">
                        <div class="col-md-3"></div>
                        <div class="col-md-8">  
                          <div *ngFor="let col of collectionsHolder; let i = index" class="roundedcorner">  
                            {{col}}
                            <i class="fa fa-times-circle" style="font-size:20px;" (click)="dropCollection(i)"></i>  
                          </div>  
                        </div>  
                      </div>
                    </div>
                    <!-- TAGS -->
                    <div class="form-group row">
                      <div class="col-xl-3 col-sm-4">
                        <label for="tags">Tags</label>
                      </div>
                      <div class="col-xl-8 col-sm-7">
                        <input class="form-control" type="text" placeholder="Enter each tag and hit 'Enter' Key."
                        formControlName="tags" (keydown.enter)="onTagsSetKeydown()"/>
                        <div *ngIf="submitted && f['tags'].errors && f['tags'].errors['required'] && tagsHolder.length==0" class="form-error">Please fill out this field</div>
                      </div>
                      <div class="row mb-2">  
                        <div class="col-md-3"></div>  
                        <div class="col-md-8">  
                          <div *ngFor="let tag of tagsHolder; let i = index" class="roundedcorner">  
                            {{tag}}   
                            <i class="fa fa-times-circle" style="font-size:20px;" (click)="dropTag(i)"></i>  
                          </div>  
                        </div>
                      </div>
                    </div>
                    <!-- STATUS -->
                    <div class="form-group row">
                      <div class="col-xl-3 col-sm-4">
                        <label for="status">Status</label>
                      </div>
                      <div class="col-xl-8 col-sm-7">
                        <select class="form-control digits" formControlName="status">
                          <option value=true>Enable</option>
                          <option value=false>Disable</option>
                        </select>
                      </div>
                      <div *ngIf="submitted && f['status'].errors && f['status'].errors['required']" class="form-error">Please fill out this field</div>
                    </div>
                    <!-- NEW -->
                    <div class="form-group row">
                      <div class="col-xl-3 col-sm-4">
                        <label for="new">New</label>
                      </div>
                      <div class="col-xl-8 col-sm-7">
                        <select class="form-control digits" formControlName="new">
                          <option value=true>New</option>
                          <option value=false>Not New</option>
                        </select>
                      </div>
                      <div *ngIf="submitted && f['new'].errors && f['new'].errors['required']" class="form-error">Please fill out this field</div>
                    </div>
                    <!-- QUANTITY -->
                    <div class="form-group row">
                      <div class="col-xl-3 col-sm-4 ">
                        <label>Stock Quantity</label>
                      </div>
                      <div class="col-xl-8 col-sm-7">
                        <input type="number" class="form-control" formControlName="stock" placeholder="100">
                          <div *ngIf="submitted && f['stock'].errors && f['stock'].errors['required']" class="form-error">Please fill out this field</div>
                      </div>
                    </div>
                    <!-- SKU -->
                    <div class="form-group row">
                      <div class="col-xl-3 col-sm-4 ">
                        <label>Stock Keeping Unit (SKU)</label>
                      </div>
                      <div class="col-xl-8 col-sm-7">
                        <input type="text" class="form-control" formControlName="sku" placeholder="SKU">
                          <div *ngIf="submitted && f['sku'].errors && f['sku'].errors['required']" class="form-error">Please fill out this field</div>
                      </div>
                    </div>
                    <!-- WEIGHT -->
                    <div class="form-group row">
                      <div class="col-xl-3 col-sm-4 ">
                        <label>Weight</label>
                      </div>
                      <div class="col-xl-8 col-sm-7">
                        <input type="number" class="form-control" formControlName="weight" placeholder="100">
                          <div *ngIf="submitted && f['weight'].errors && f['weight'].errors['required']" class="form-error">Please fill out this field</div>
                      </div>
                    </div>
                    <!-- DIMENSIONS -->
                    <div class="form-group row">
                      <div class="col-xl-3 col-sm-4 ">
                        <label>Dimensions</label>
                      </div>
                      <div class="col-xl-8 col-sm-7">
                        <div class="block-dimensions">
                          <div class="subblock-dimension"><span>Length (cm)</span><input type="number" class="form-control" [(ngModel)]="dimensionL" placeholder="Panjang" [ngModelOptions]="{standalone: true}"></div>
                          <div class="subblock-dimension"><span>Width (cm)</span><input type="number" class="form-control" [(ngModel)]="dimensionW" placeholder="Lebar" [ngModelOptions]="{standalone: true}"></div>
                          <div class="subblock-dimension"><span>Thickness (cm)</span><input type="number" class="form-control" [(ngModel)]="dimensionT" placeholder="Tinggi" [ngModelOptions]="{standalone: true}"></div>
                        </div>
                        <div *ngIf="submitted && f['dimensions'].errors && f['dimensions'].errors['required']" class="form-error">Please fill out this field</div>
                      </div>
                    </div>
                    <!-- OPTIONS -->
                    <div class="form-group row">
                      <div class="col-xl-11 col-sm-11">
                        <label>Options</label>
                      </div>
                    </div>
                    <div class="form-group row disabled-container" style="margin-right: 12px;">
                      <div class="col-xl-11 col-sm-11 border options-box" formArrayName="option">
                        <div *ngFor="let option of options.controls; let opt = index" class="border box-option">
                          <div [formGroupName]="opt">
                            <label>Option {{ opt + 1 }}:</label>
                            <input formControlName="name" placeholder="Name" class="form-control form-option">
                            <button type="button" (click)="deleteOption(opt)" class="btn-add fa fa-trash-o" style="color:red"></button>
                            <button type="button" (click)="addSuboption(opt)" class="btn-add">Add Suboption</button>
                            <div formArrayName="sub_options">
                              <div *ngFor="let suboption of getSuboptions(opt).controls; let sub = index" class="border box-suboption">
                                <div [formGroupName]="sub">
                                  <label>Suboption {{ sub + 1 }}:</label>
                                  <input formControlName="name" placeholder="Name" class="form-control form-option">
                                  <button type="button" (click)="deleteSuboption(opt, sub)" class="btn-add fa fa-trash-o" style="color:red"></button>
                                  <button type="button" (click)="addPrice(opt, sub)" class="btn-add">Add Price Adjustment</button>
                                  <div formArrayName="prices">
                                    <div *ngFor="let price of getPrice(opt, sub).controls; let prc = index" class="box-price">
                                      <div [formGroupName]="prc" class="box-adjustment">
                                        <input formControlName="min" placeholder="Min" type="number" class="form-control form-price">
                                        <input formControlName="max" placeholder="Max" type="number" class="form-control form-price">
                                        <input formControlName="value" placeholder="Value" type="number" class="form-control form-price">
                                        <button type="button" (click)="deletePrice(opt, sub, prc)" class="btn-del-price fa fa-trash-o" style="color:red"></button>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <button type="button" class="btn btn-primary me-1" (click)="addOption()">Add Option</button>
                      </div>
                    </div>
                  </div>
                </form>
                <div class="form-group row mb-0">
                    <div class="col-sm-12 offset-xl-5 offset-sm-6">
                      <div class="product-buttons">
                          <button type="button" class="btn btn-primary me-1" (click)="onSubmit()">Update</button>
                          <button type="button" class="btn btn-light" (click)="onReset()">Cancel</button>
                        </div>
                    </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <!-- Container-fluid Ends-->
  