import { Component, OnInit } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Image } from '@ks89/angular-modal-gallery';
import { NgbRatingConfig } from '@ng-bootstrap/ng-bootstrap';
import { ActivatedRoute } from '@angular/router';
import { ProductService } from 'src/app/shared/service/product.service';
import { FormArray, FormBuilder, FormGroup } from '@angular/forms';

@Component({
  selector: 'app-product-detail',
  templateUrl: './product-detail.component.html',
  styleUrls: ['./product-detail.component.scss'],
  providers: [NgbRatingConfig]
})
export class ProductDetailComponent implements OnInit {
  productId: number = 0;
  product: any;
  counter: number = 0;
  finalPrice: number = 0;

  optionForm: FormGroup

  public images: Image[] = [
    new Image(0, { img: 'assets/images/pro3/2.jpg' }, { img: 'assets/images/pro3/1.jpg' })]

  constructor(
    private modalService: NgbModal,
    private activatedRoute: ActivatedRoute,
    private productService: ProductService,
    private fb: FormBuilder
  ) {
    this.productId = Number(this.activatedRoute.snapshot.paramMap.get('productId'))
    this.productService.productDetailGet(this.productId).subscribe((res) => {
      console.log(res)
      this.product = res
      res.images.forEach(element => {
        this.images.push(new Image(element.id, { img: element.image_url }, { img: element.image_url }))
      });
      this.images.splice(0, 1);
      this.optionForm = this.fb.group({
        option: this.fb.array([
        ])
      })
      for (var row of this.product.options) {
        this.addOption(row)
      }
      this.calcFinalPrice();
    })
  }

  private getOption(sub_options){
    return this.fb.group({
      value: sub_options
    });
  }

  addOption(row) {
    const control = <FormArray>this.optionForm.controls['option'];
    control.push(this.getOption(row.sub_options[0]));
  }

  ngOnInit() { }

  increment() {
    this.counter++;
    this.calcFinalPrice();
  }

  decrement() {
    if (this.counter > 1) this.counter--;
    this.calcFinalPrice();
  }
  
  calcFinalPrice() {
    if (this.counter == 0) {
      this.counter = 1;
    }
    var adjustment = this.getAdjustmentForQuantity(this.counter, this.product.price);
    for (let prices of this.optionForm.value.option) {
      if (prices.value !== '') {
        adjustment += this.getAdjustmentForQuantity(this.counter, prices.value.prices);
      }
    }
    this.finalPrice = adjustment*this.counter;
  }

  getAdjustmentForQuantity(quantity: number, adjustment: any[]): number {
    const adjust = adjustment.find(
      adjustment => quantity >= adjustment.min && quantity <= adjustment.max
    );
    return adjustment ? adjust.value : 0;
  }
}
